import React, { useMemo } from "react";
import { IKirbyIcon } from "@gk-lab/kirbyreact";

interface Props {
  icon: IKirbyIcon;
  className?: string;
  variant?: "white";
  isEnd?: boolean;
}

export default function Icon(props: Props): React.JSX.Element {
  const { icon, className, variant, isEnd } = props;

  const cN = useMemo(() => {
    return [
      "icon",
      variant === "white" ? "icon-white" : "hover:icon-hover",
      "h-7 md:h-8",
      isEnd ? "ml-2 mr-[-8px]" : "",
      !isEnd ? "mr-2" : "",
      className,
    ].join(" ");
  }, [variant, className, isEnd]);

  return <img className={cN} src={icon.url} alt={icon.alt} />;
}
