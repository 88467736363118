import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { filterHash } from "../utils/utils";

export function useHash() {
  const location = useLocation();
  const [hash, setHash] = useState(filterHash(window.location.hash));
  const navigate = useNavigate();

  const hashChangeHandler = useCallback((event: HashChangeEvent) => {
    event.stopPropagation();
    event.preventDefault();
    // setHash(filterHash(window.location.hash));
  }, []);

  useEffect(() => {
    window.addEventListener("hashchange", hashChangeHandler);
    return () => {
      window.removeEventListener("hashchange", hashChangeHandler);
    };
  });

  useEffect(() => {
    setHash(filterHash(location.hash));
  }, [location.hash]);

  const updateHash = useCallback(
    (newHash: string) => {
      if (newHash !== hash) {
        // window.location.hash = newHash
        navigate(`#${newHash}`, { replace: true });
      }
    },
    [hash, navigate]
  );

  return { hash, updateHash, location };
}
