import { ResponsiveSx } from "@gk-lab/kirbyreact";
import { SxProps, Theme } from "@mui/system";
import { Box } from "@mui/material";

export function getResponsiveUnit(value?: string): string {
  let unit = "";
  if (value) {
    unit = value === "pc" ? "%" : value;
  }
  return unit;
}

export function getResponsiveValues(value: ResponsiveSx) {
  const unit = getResponsiveUnit(value.unit);
  return {
    xs: `${value.xs}${unit}`,
    ...(value.sm && { sm: `${value.sm}${unit}` }),
    ...(value.md && { md: `${value.md}${unit}` }),
    ...(value.lg && { lg: `${value.lg}${unit}` }),
    ...(value.xl && { xl: `${value.xl}${unit}` }),
  };
}

export function parsePadding(
  css: { [key: string]: ResponsiveSx } | undefined,
  important?: boolean
): SxProps<Theme> {
  if (css) {
    const v = {
      ...(css.paddingleft && {
        paddingLeft: getResponsiveValues(css.paddingleft),
      }),
      ...(css.paddingright && {
        paddingRight: getResponsiveValues(css.paddingright),
      }),
      ...(css.paddingtop && {
        paddingTop: getResponsiveValues(css.paddingtop),
      }),
      ...(css.paddingbottom && {
        paddingBottom: getResponsiveValues(css.paddingbottom),
      }),
    };
    return v;
  }
  return {};
}

export function cssWrapper(
  children: JSX.Element,
  css: { [key: string]: ResponsiveSx } | undefined,
  className?: string
): JSX.Element {
  if (css) {
    return (
      <Box
        className={["css-wrapper", className ?? ""].join(" ")}
        sx={{
          display: "inline-flex",
          width: "100%",
          ...parsePadding(css),
        }}
        tabIndex={-1}
      >
        {children}
      </Box>
    );
  }
  return children;
}

export function filterHash(value: string) {
  return value ? value.replace("#", "") : "";
}

export function scrollBodyTo(pos: number, smooth: boolean) {
  setTimeout(() => {
    const yOffset = 0;
    const y = pos + window.scrollY + yOffset;
    window.scrollTo(smooth ? { top: y, behavior: "smooth" } : { top: y });
  }, 50);
}

export function scrollBodyToElement(id: string, smooth: boolean = true) {
  const el = document.getElementById(id);
  if (el) {
    scrollBodyTo((el?.getBoundingClientRect() ?? { top: 0 }).top, smooth);
  } else {
    console.log("checkHash - not found: ", id);
  }
}

export function elementIsVisibleInViewport(
  el?: HTMLElement | null,
  partiallyVisible = true
) {
  if (el) {
    const { top, bottom } = el.getBoundingClientRect();
    const { innerHeight } = window;
    return partiallyVisible
      ? (top > 0 && top < innerHeight * 0.5) ||
          (bottom > innerHeight * 0.5 && bottom <= innerHeight)
      : top >= 0 && bottom <= innerHeight;
  } else {
    return false;
  }
}
