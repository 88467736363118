import React, { useCallback, useMemo } from "react";
import { BlockHeadline } from "@gk-lab/kirbyreact";

interface Props {
  data?: BlockHeadline;
}

export default function Headline(props: Props): React.JSX.Element {
  const { data } = props;

  const align = useCallback(() => {
    switch (data?.align) {
      case "center":
        return "text-center";
      case "right":
        return "text-right";
      case "left":
      default:
        return "";
    }
  }, [data?.align]);

  const padding = useCallback(() => {
    switch (data?.level) {
      case "h1":
        return "px-2 md:px-4 py-4";
      case "h2":
        return "px-2 md:px-4 pt-4 pb-0";
      case "h3":
        return "px-2 md:px-4 pt-0 pb-2";
      case "h4":
        return "px-2 md:px-4 pt-0 pb-1";
      case "h5":
        return "px-2 md:px-4 py-1";
      case "h6":
        return "px-2 md:px-4 py-1";
      default:
        return "px-2 md:px-4 py-2";
    }
  }, [data?.level]);

  const size = useCallback(() => {
    switch (data?.level) {
      case "h1":
        return "text-8xl md:text-9xl";
      case "h2":
        return "text-6xl md:text-8xl";
      case "h3":
        return "text-4xl md:text-6xl";
      case "h4":
        return "text-2xl md:text-4xl bold";
      case "h5":
        return "bold";
      default:
        return "";
    }
  }, [data?.level]);

  const transform = useCallback(() => {
    switch (data?.level) {
      case "h1":
      case "h2":
      case "h3":
      case "h4":
        return "uppercase";
      case "h5":
        return "";
      default:
        return "";
    }
  }, [data?.level]);

  const variant = useCallback(() => {
    switch (data?.variant) {
      case "primary":
        return "text-white";
      case "secondary":
        return "text-gray-200";
      default:
        return "";
    }
  }, [data?.variant]);

  const element = useMemo(() => {
    const basics = [align(), padding(), size(), variant(), transform()];

    const cN = basics.join(" ");

    switch (data?.level) {
      case "h1":
        return (
          <h1 className={cN} dangerouslySetInnerHTML={{ __html: data.text }} />
        );
      case "h2":
        return (
          <h2 className={cN} dangerouslySetInnerHTML={{ __html: data.text }} />
        );
      case "h3":
        return (
          <h3 className={cN} dangerouslySetInnerHTML={{ __html: data.text }} />
        );
      case "h4":
        return (
          <h4 className={cN} dangerouslySetInnerHTML={{ __html: data.text }} />
        );
      case "h5":
        return (
          <h5 className={cN} dangerouslySetInnerHTML={{ __html: data.text }} />
        );
      case "h6":
        return (
          <h6 className={cN} dangerouslySetInnerHTML={{ __html: data.text }} />
        );
      default:
        return <div className={"uppercase"}>{data?.text}</div>;
    }
  }, [data, align, padding, size, transform, variant]);

  return element;
}
