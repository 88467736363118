import React, { JSX } from "react";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { PageRoot } from "./pages/PageRoot";
import { IKirbySection, useKirbyData } from "@gk-lab/kirbyreact";
import { CONTENTPATH } from "./config";
import { PageNotFound } from "./pages/PageNotFound";
import { PageHome } from "./pages/PageHome";
import Navigation from "./components/Navigation/Navigation";
import ScrollToTopOnPage from "./components/ScrollToTopOnPage/ScrollToTopOnPage";
import Twclasses from "./components/TWClasses/TWClasses";
import { BackButtonProvider } from "./context/BackButtonProvider";
import { PageGallery } from "./pages/PageGallery";

function renderRoutes(
  sections?: IKirbySection[],
  isRoot: boolean = true,
  homeUrl: string = "/"
): JSX.Element[] {
  let routes: React.JSX.Element[] = [];

  if (sections) {
    sections?.forEach((section) => {
      // console.log('renderRoutes - section.id: ', section.id, (section.id === "home"))
      switch (section.id) {
        case "home":
          routes.push(
            <Route
              key={section.id}
              path={`/${section.url}`}
              element={<PageHome data={section} />}
            />
          );
          break;
        case "gallery":
          routes.push(
            <Route
              key={section.id}
              path={`/${section.url}/*`}
              element={<PageGallery data={section} backPath={`/${homeUrl}`} />}
            />
          );
          break;
        default:
          routes.push(
            <Route
              key={section.id}
              path={`/${section.url}`}
              element={<PageRoot data={section} />}
            />
          );
          break;
      }

      // RENDER CHILD-SECTION-ROUTES TOO!!
      if (section.sections) {
        const children = renderRoutes(section.sections, false, sections[0].url);
        children.forEach((r) => routes.push(r));
      }
    });
  }
  if (isRoot) {
    routes.push(
      <Route
        key={"tohome"}
        path={`/`}
        element={
          <Navigate
            to={sections && sections[0] ? `/${sections[0].url}` : "/home"}
          />
        }
      />
    );
    routes.push(
      <Route key={"notfound"} path={"*"} element={<PageNotFound />} />
    );
  }

  return routes;
}

function App() {
  const { data, isLoading } = useKirbyData(CONTENTPATH);

  console.log("App - sections: ", data?.sections);

  return (
    <BrowserRouter>
      <BackButtonProvider>
        {isLoading && (
          <div
            className={
              "w-full h-full flex flex-col gap-2 items-center mt-[30vh]"
            }
          >
            <img
              className={"w-32"}
              src={"/img/nu-index-logo.png"}
              alt={"nu-logo"}
            />
            <p className={"text-2xl text-white"}>loading</p>
          </div>
        )}
        {!isLoading && data && (
          <div className="App flex font-sans text-white bg-black text-2xl md:text-3xl justify-center">
            <Twclasses />
            <ScrollToTopOnPage />
            {/*<Jsonviewer data={data} />*/}
            <div className={"flex w-full max-w-screen-2xl"}>
              <Routes>{renderRoutes(data.sections)}</Routes>
            </div>
            <header>
              <Navigation />
            </header>
          </div>
        )}
      </BackButtonProvider>
    </BrowserRouter>
  );
}

export default App;
