import React, { SyntheticEvent } from "react";
import Button from "../Button/Button";
import { BlockType } from "@gk-lab/kirbyreact";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setIsBack,
  useBackDispatch,
  useBackState,
} from "../../context/BackButtonProvider";

interface Props {
  fallbackTo?: string;
  forceTo?: string;
}

export default function ActionBar(props: Props): React.JSX.Element {
  const { fallbackTo, forceTo } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const bbDispatch = useBackDispatch();
  const { homeVisited } = useBackState();

  function handleClick(e: SyntheticEvent) {
    e.preventDefault();

    if (forceTo) {
      navigate(forceTo, { replace: true });
    } else if (location.key !== "default" && homeVisited) {
      bbDispatch(setIsBack());
      navigate(-1);
    } else if (fallbackTo) {
      navigate(fallbackTo, { replace: true });
    } else {
      navigate("/");
    }
  }

  return (
    <div
      className={
        "fixed flex top-0 left-0 right-0 w-full px-2 md:px-8 py-4 bg-black justify-center"
      }
    >
      <div className={"flex w-full max-w-screen-2xl"}>
        <Button
          onClick={handleClick}
          data={{
            type: BlockType.BUTTON,
            text: "Zurück",
            icon: "close",
          }}
        />
        {/*<p className={"px-2"}>
          Location: {location.key}, Fallback: {fallbackTo}, homeVisited:{" "}
          {homeVisited ? "true" : "false"}
        </p>*/}
      </div>
    </div>
  );
}
