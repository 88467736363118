import React, { useCallback, useMemo } from "react";
import { BlockText } from "@gk-lab/kirbyreact";

interface Props {
  data?: BlockText;
  className?: string;
}

export default function Text(props: Props): React.JSX.Element {
  const { data, className } = props;
  const variant = useCallback(() => {
    switch (data?.variant) {
      case "primary":
        return "text-white leading-10";
      case "secondary":
        return "font-light text-gray-400";
      default:
        return "font-light text-white leading-10";
    }
  }, [data?.variant]);

  const align = useCallback(() => {
    switch (data?.align) {
      case "center":
        return "text-center";
      case "right":
        return "text-right";
      case "left":
      default:
        return "";
    }
  }, [data?.align]);

  const element = useMemo(() => {
    const basics = [
      "text",
      "px-4 md:px-8 py-8",
      align(),
      variant(),
      className ? className : "",
    ];

    const cN = basics.join(" ");

    return (
      <div
        className={cN}
        dangerouslySetInnerHTML={{ __html: data?.text ?? "" }}
      />
    );
  }, [data, align, className, variant]);

  return element;
}
