import React from "react";

export default function Twclasses() {
  const cN = [
    "hidden",
    "pt-1 pt-2 pt-3 pt-4 pt-8 pt-12 pt-16 pt-24 pt-32 pt-40 pt-48 pt-56 pt-64 pt-72 pt-80",
    "pb-1 pb-2 pb-3 pb-4 pb-8 pb-12 pb-16 pb-24 pb-32 pb-40 pb-48 pb-56 pb-64 pb-72 pb-80",
    "py-1 py-2 py-3 py-4 py-8 py-12 py-16 py-24 py-32 py-40 py-48 py-56 py-64 py-72 py-80",
    "pl-1 pl-2 pl-3 pl-4 pl-8 pl-12 pl-16 pl-24 pl-32 pl-40 pl-48 pl-56 pl-64 pl-72 pl-80",
    "pr-1 pb-2 pb-3 pb-4 pb-8 pb-12 pb-16 pb-24 pb-32 pb-40 pb-48 pb-56 pb-64 pb-72 pb-80",
    "px-1 px-2 px-3 px-4 px-8 px-12 px-16 px-24 px-32 px-40 px-48 px-56 px-64 px-72 px-80",
  ].join(" ");
  return <div className={cN} />;
}
