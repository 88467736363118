import React, { useMemo } from "react";
import { IKirbyImage } from "../../../.yalc/@gk-lab/kirbyreact";
import { useParams } from "react-router-dom";
import ActionBar from "../ActionBar/ActionBar";
import Button from "../Button/Button";
import { BlockType } from "@gk-lab/kirbyreact";
import SwipeContainer from "../SwipeContainer/SwipeContainer";

interface Props {
  images?: IKirbyImage[];
  path?: string;
}

export function GalleryImage(props: Props): React.JSX.Element {
  const { images, path } = props;
  const params = useParams();

  const image: IKirbyImage | undefined = useMemo(() => {
    return images?.find((img) => img.name === params.id);
  }, [params, images]);

  const nextImage: string | undefined = useMemo(() => {
    let value: string | undefined = undefined;
    images?.forEach((img, idx) => {
      if (img.name === params.id) {
        if (idx < images?.length - 1) {
          value = images[idx + 1].name;
        } else {
          value = images[0].name;
        }
      }
    });
    return value;
  }, [images, params.id]);

  const prevImage: IKirbyImage | undefined = useMemo(() => {
    let value: string | undefined = undefined;
    images?.forEach((img, idx) => {
      if (img.name === params.id) {
        if (idx > 0) {
          value = images[idx - 1].name;
        } else {
          value = images[images?.length - 1].name;
        }
      }
    });
    return value;
  }, [images, params.id]);

  return (
    <SwipeContainer
      className={
        "p-gallery-image absolute top-0 bottom-0 left-0 right-0 flex w-full max-w-screen-xl bg-black mx-auto"
      }
      leftPath={nextImage && `/${path}/${nextImage}`}
      rightPath={prevImage && `/${path}/${prevImage}`}
      replace={true}
    >
      <ActionBar fallbackTo={path ? `/${path}` : "/home"} />
      {image && (
        <>
          <div
            style={{
              backgroundImage: `url(${image.url})`,
              backgroundPosition: "center",
            }}
            className={"bg-black w-full h-full bg-contain bg-no-repeat"}
          />
          <div
            className={
              "absolute bottom-0 left-0 right-0 flex space-4 gap-4 flex-row justify-end p-4"
            }
          >
            {prevImage && (
              <Button
                data={{
                  type: BlockType.LINK,
                  to: `${path}/${prevImage}`,
                  text: "Zurück",
                  icon: "back",
                }}
                replace={true}
              />
            )}
            {nextImage && (
              <Button
                data={{
                  type: BlockType.LINK,
                  to: `${path}/${nextImage}`,
                  text: "Vor",
                  endicon: "forward",
                }}
                replace={true}
              />
            )}
          </div>
        </>
      )}
    </SwipeContainer>
  );
}
