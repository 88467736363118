import { useEffect, useState } from "react";
import { useLocation, useNavigationType } from "react-router-dom";
import { scrollBodyTo, scrollBodyToElement } from "../../utils/utils";
import {
  BackStatus,
  reset,
  useBackDispatch,
  useBackState,
} from "../../context/BackButtonProvider";

export default function ScrollToTopOnPage(): null {
  const navigationType = useNavigationType();
  const { pathname, hash } = useLocation();
  const [lastPath, setLastPath] = useState<string | undefined>(pathname);

  const { backState } = useBackState();
  const bbDispatch = useBackDispatch();

  // @ts-ignore
  useEffect(() => {
    if (navigationType !== "POP") {
      // console.log('ScrollToTopOnPage', lastPath, pathname)
      if (lastPath !== pathname) {
        window.scrollTo(0, 0);
      }
    } else if (backState === BackStatus.INIT) {
      setTimeout(() => {
        checkHash(hash.replace("#", ""), true);
      }, 500);
    }
    setLastPath(pathname);
    bbDispatch(reset());
  }, [navigationType, pathname, lastPath, backState]);

  function checkHash(targetHash: string, smooth: boolean) {
    //console.log('checkHash - targetHash: ', targetHash)
    if (targetHash === "") {
      scrollBodyTo(0, smooth);
    } else if (targetHash) {
      scrollBodyToElement(`section-${targetHash}`, smooth);
    }
  }

  return null;
}
