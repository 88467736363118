import React, { createContext, PropsWithChildren } from "react";

enum ACTIONS {
  SET_IS_BACK = "SET_ISBACK",
  RESET = "RESET",
  SET_IS_HOME = "INITIAL_BACK_USED",
}

export enum BackStatus {
  INIT = "INIT",
  ISBACK = "ISBACK",
  DEFAULT = "DEFAULT",
}

type InitialStateType = {
  backState: BackStatus;
  homeVisited: boolean;
};

type PayloadType = {
  type: ACTIONS;
};

const initialState: InitialStateType = {
  backState: BackStatus.INIT,
  homeVisited: false,
};

const BackButtonContext = createContext<InitialStateType>(initialState);
const BackButtonDispatchContext = createContext<React.Dispatch<any>>(
  () => null
);

const setIsHome = () => {
  return {
    type: ACTIONS.SET_IS_HOME,
  };
};
const setIsBack = () => {
  return {
    type: ACTIONS.SET_IS_BACK,
  };
};

const reset = () => {
  return {
    type: ACTIONS.RESET,
  };
};

const BackButtonReducer = (state: InitialStateType, action: PayloadType) => {
  switch (action.type) {
    case ACTIONS.SET_IS_BACK: {
      return {
        ...state,
        backState: BackStatus.ISBACK,
      };
    }
    case ACTIONS.RESET: {
      return {
        ...state,
        backState: BackStatus.DEFAULT,
      };
    }
    case ACTIONS.SET_IS_HOME: {
      return {
        ...state,
        homeVisited: true,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

function BackButtonProvider(props: PropsWithChildren): React.JSX.Element {
  const [state, dispatch] = React.useReducer(BackButtonReducer, initialState);

  return (
    <BackButtonContext.Provider value={state}>
      <BackButtonDispatchContext.Provider value={dispatch}>
        {props.children}
      </BackButtonDispatchContext.Provider>
    </BackButtonContext.Provider>
  );
}

function useBackState() {
  const context = React.useContext(BackButtonContext);
  if (context === undefined) {
    throw new Error("useAppState must be used within a AppProvider");
  }
  return context;
}

function useBackDispatch() {
  const context = React.useContext(BackButtonDispatchContext);
  if (context === undefined) {
    throw new Error("useAppDispatch must be used within a AppProvider");
  }
  return context;
}

export {
  BackButtonProvider,
  useBackState,
  useBackDispatch,
  setIsBack,
  setIsHome,
  reset,
};
