import {
  Block,
  BlockType,
  getKirbyBlockElement,
  KirbyBlockGroup,
} from "@gk-lab/kirbyreact";
import React from "react";
import { CustomBlock, CustomBlockType } from "../types/Block";
import Section from "../components/Section/Section";
import Home from "../components/Home/Home";
import Post from "../components/Post/Post";
import Button from "../components/Button/Button";
import Headline from "../components/Headline/Headline";
import Text from "../components/Text/Text";
import Event from "../components/Event/Event";
import Preview from "../components/Preview/Preview";
import Media from "../components/Media/Media";
import Gallery from "../components/Gallery/Gallery";

export function getBlockElement(
  b: Block | CustomBlock,
  idx: number,
  scope?: string
): React.JSX.Element | null {
  if (b) {
    switch (b.type) {
      case CustomBlockType.SECTION:
        return <Section data={b} key={`section-${idx}`} />;
      case CustomBlockType.POST:
        return <Post data={b} key={`post-${idx}`} />;
      case CustomBlockType.EVENT:
        return <Event data={b} key={`event-${idx}`} scope={scope} />;
      case CustomBlockType.MEDIA:
        return <Media data={b} key={`media-${idx}`} />;
      case CustomBlockType.GALLERY:
        return <Gallery data={b} key={`gallery-${idx}`} />;
      case CustomBlockType.ANIMATION:
        if (b.id === "home") {
          return (
            <Home key={`home-${idx}`}>
              {b.blocks?.map((b: Block | CustomBlock, idx) =>
                getBlockElement(b, idx, "preview")
              )}
            </Home>
          );
        }
        return <div key={`animation-${idx}`} />;
      case CustomBlockType.PREVIEW: {
        const elements = b.blocks?.map((b: Block | CustomBlock, idx) =>
          getBlockElement(b, idx, scope)
        );
        return (
          <Preview data={b} key={`preview-${idx}`}>
            {elements}
          </Preview>
        );
      }
      case BlockType.BLOCKGROUP:
        const elements = b.items?.map((b: Block, idx) =>
          getBlockElement(b, idx, scope)
        );
        return <KirbyBlockGroup data={b} elements={elements} />;
      case BlockType.BUTTON:
        return <Button data={b} key={`button-${idx}`} />;
      case BlockType.HEADLINE:
        return <Headline data={b} key={`headline-${idx}`} />;
      case BlockType.TEXT:
        return <Text data={b} key={`text-${idx}`} />;
      default:
        return getKirbyBlockElement(b, idx, scope);
    }
  }
  return null;
}
