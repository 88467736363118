import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AppProvider } from "@gk-lab/kirbyreact";
import { CONTENTPATH } from "./config";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AppProvider contentPath={CONTENTPATH}>
      <App />
    </AppProvider>
  </React.StrictMode>
);
