import React, { PropsWithChildren } from "react";
import { SwipeEventData, useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";

export type SwipeContainerProps = {
  onSwipedLeft?(e: SwipeEventData): void;
  onSwipedRight?(e: SwipeEventData): void;
  onSwipedDown?(e: SwipeEventData): void;
  downPath?: string;
  leftPath?: string;
  rightPath?: string;
  replace?: Boolean;
  className?: string;
  disabled?: boolean;
};

// https://www.npmjs.com/package/react-swipeable

export default function SwipeContainer(
  props: PropsWithChildren<SwipeContainerProps>
): React.JSX.Element {
  const {
    disabled,
    leftPath,
    rightPath,
    replace,
    onSwipedLeft,
    onSwipedRight,
    onSwipedDown,
    className,
  } = props;
  const navigate = useNavigate();
  const config = {
    delta: { down: 20, left: 50, right: 50 },
    swipeDuration: 500,
    // onSwiped,       // After any swipe   (SwipeEventData) => void
    onSwipedLeft: (e: SwipeEventData) => {
      if (!disabled) {
        if (leftPath) navigate(leftPath, { replace: Boolean(replace) });
        if (onSwipedLeft) onSwipedLeft(e);
      }
    }, // After LEFT swipe  (SwipeEventData) => void
    onSwipedRight: (e: SwipeEventData) => {
      if (!disabled) {
        if (rightPath) navigate(rightPath, { replace: Boolean(replace) });
        if (onSwipedRight) onSwipedRight(e);
      }
    }, // After RIGHT swipe (SwipeEventData) => void
    onSwipedDown: (e: SwipeEventData) => {
      if (!disabled) {
        if (onSwipedDown) onSwipedDown(e);
      }
    },

    // onSwipedUp,     // After UP swipe    (SwipeEventData) => void

    // After DOWN swipe  (SwipeEventData) => void
    // onSwipeStart,   // Start of swipe    (SwipeEventData) => void *see details*
    // onSwiping,      // During swiping    (SwipeEventData) => void
    // onTap,          // After a tap       ({ event }) => void

    // Pass through callbacks, event provided: ({ event }) => void
    // onTouchStartOrOnMouseDown, // Called for `touchstart` and `mousedown`
    // onTouchEndOrOnMouseUp,     // Called for `touchend` and `mouseup`
  };

  const handlers = useSwipeable({
    // onSwiped: (eventData) => console.log("User Swiped!", eventData),
    ...config,
  });

  return (
    <div className={`SwipeContainer ${className}`} {...handlers}>
      {props.children}
    </div>
  );
}
