import React from "react";
import { Route, Routes } from "react-router-dom";
import { GalleryImage } from "../components/GalleryImage/GalleryImage";
import ActionBar from "../components/ActionBar/ActionBar";
import { useData } from "@gk-lab/kirbyreact";
import { CONTENTPATH } from "../config";
import Gallery from "../components/Gallery/Gallery";
import { SectionGallery } from "../types/Section";

interface Props {
  data?: SectionGallery;
  backPath: string;
}

export function PageGallery(props: Props): React.JSX.Element {
  const { data, backPath } = props;

  const { data: pageData, isLoading } = useData(
    data?.url,
    `${CONTENTPATH}?r=${data?.url}`,
    [data?.url]
  );

  return (
    <div className={"p-gallery flex w-full flex-col bg-black"}>
      <ActionBar fallbackTo={backPath} />
      <h2 className={"text-2xl md:text-4xl px-2 py-6 md:py-4 self-end"}>
        {data?.title}
      </h2>
      {isLoading && (
        <p className={"text-xl text-white px-2"}>Bilder werden geladen...</p>
      )}
      {!isLoading && pageData?.blocks && (
        <Routes>
          <Route
            path={`/:id`}
            element={
              <GalleryImage
                path={pageData?.blocks.url}
                images={pageData?.blocks.images}
              />
            }
          />
          <Route path={"*"} element={<Gallery data={pageData?.blocks} />} />
        </Routes>
      )}
    </div>
  );
}
