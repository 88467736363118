import React, { useMemo, useState } from "react";
import { BlockMedia } from "../../types/Block";
import { BlockType, FlexAlign, useAppState } from "@gk-lab/kirbyreact";
import Button from "../Button/Button";
import Text from "../Text/Text";

interface Props {
  data?: BlockMedia;
}

export default function Video(props: Props): React.JSX.Element {
  const { data } = props;
  const [showDisclaimer, setShowDisclaimer] = useState(Boolean(data?.yt));
  // console.log('default - Post: ', data)

  const appData = useAppState();

  const wrapperClass = useMemo(() => {
    return [
      "flex flex-col",
      "w-full",
      "max-w-screen-md",
      "shadow-gray-700 shadow-sm",
    ].join(" ");
  }, []);

  return (
    <div className={"video flex w-full mb-8 justify-center px-2"}>
      <div className={wrapperClass}>
        <div className={"p-4 w-full bg-black/[.9] rounded-xl overflow-hidden"}>
          {data?.subtitle && (
            <h5 className={"text-2xl pt-0 uppercase"}>{data?.subtitle}</h5>
          )}
          <h3 className={"text-5xl pb-0 mb-0 uppercase"}>{data?.title}</h3>
          {data?.text && (
            <div
              className={
                "flex font-thin text-2xl text-gray-500 flex-grow w-full py-2"
              }
              dangerouslySetInnerHTML={{ __html: data.text }}
            />
          )}
          {showDisclaimer && (
            <div
              className={
                "content flex flex-col w-full bg-black items-center py-4 h-[240px] md:h-[320px]"
              }
            >
              <Text
                data={{
                  type: BlockType.TEXT,
                  text: appData?.yt?.disclaimer,
                  variant: "secondary",
                }}
                className={"text-xl md:text-3xl text-center"}
              />
              <Button
                data={{
                  type: BlockType.BUTTON,
                  text: appData?.yt?.button,
                  align: FlexAlign.center,
                }}
                onClick={() => setShowDisclaimer(false)}
              />
            </div>
          )}
          {!showDisclaimer && data?.yt && (
            <div
              className={
                "video-frame flex flex-col w-full bg-black items-center py-4 aspect-video"
              }
            >
              <iframe
                title={"yt-frame"}
                width={"100%"}
                height={"320px"}
                src={data?.yt}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
