import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IKirbySection } from "@gk-lab/kirbyreact";
import { getBlockElement } from "../utils/sectionUtils";
import { useHash } from "../hooks/useHash";
import { elementIsVisibleInViewport } from "../utils/utils";
import { setIsHome, useBackDispatch } from "../context/BackButtonProvider";

interface Props {
  data?: IKirbySection;
}

export function PageHome(props: Props): React.JSX.Element {
  const { data } = props;
  const { updateHash } = useHash();
  const [elementIdInView, setElementIdInView] = useState<string>("");
  const [elementIds, setElementIds] = useState<string[]>([]);

  const bbDispatch = useBackDispatch();

  const getElementIdInView = useCallback(() => {
    // console.log('PageHome - scroll: ', elementIds);
    const match = elementIds.find((id) =>
      elementIsVisibleInViewport(document.getElementById(`section-${id}`), true)
    );
    return match;
  }, [elementIds]);

  const onScroll = useCallback(() => {
    // console.log('PageHome - scroll: ', elementIds);
    const m = getElementIdInView();
    setElementIdInView(m ?? "");
  }, [getElementIdInView]);

  useEffect(() => {
    if (elementIdInView) {
      updateHash(elementIdInView);
    }
  }, [elementIdInView, updateHash]);

  // @ts-ignore
  useEffect(() => {
    bbDispatch(setIsHome());
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    // onScroll();
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [elementIds, onScroll]);

  const children = useMemo(() => {
    return data?.sections?.map((section: IKirbySection, idx1: number) => {
      return section?.blocks?.map((block, idx: number) => {
        if (block.id && elementIds.indexOf(block.id) === -1)
          setElementIds((oldValue) => [...oldValue, block.id!]);

        return getBlockElement(block, (idx1 + 1) * idx, "section-list");
      });
    });
    /*
    return (
      data?.blocks &&
      data?.blocks.map((block, idx: number) => {
        if (block.id && elementIds.indexOf(block.id) === -1)
          setElementIds((oldValue) => [...oldValue, block.id!]);
        return getBlockElement(block, idx, "section-list");
      })
    );*/
  }, [data, elementIds]);

  return (
    <div className={"p-home flex w-full flex-col bg-black"}>{children}</div>
  );
}
