import React from "react";
import { IKirbySection } from "@gk-lab/kirbyreact";
import { getBlockElement } from "../utils/sectionUtils";
import ActionBar from "../components/ActionBar/ActionBar";

interface Props {
  data?: IKirbySection;
}

export function PageRoot(props: Props): React.JSX.Element {
  const { data } = props;
  return (
    <div
      className={
        "flex w-full flex-col items-center pt-[4rem] pb-[16rem] bg-black min-h-screen gap-2"
      }
    >
      <ActionBar key={"action-bar"} fallbackTo={"/home"} />
      {data?.blocks?.map((b, idx) => getBlockElement(b, idx))}
    </div>
  );
}
