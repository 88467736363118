import React, { CSSProperties, SyntheticEvent, useMemo } from "react";
import { BlockButton, BlockLink, FlexAlign, useIcon } from "@gk-lab/kirbyreact";
import { Link, useNavigate } from "react-router-dom";
import { BlockType } from "@gk-lab/kirbyreact/dist/esm/types/Block";
import Icon from "../Icon/Icon";

interface Props {
  data?: BlockButton | BlockLink;
  className?: string;
  style?: CSSProperties;
  active?: Boolean;
  onClick?(e: SyntheticEvent): void;
  replace?: boolean;
}

export default function Button(props: Props): React.JSX.Element {
  const { data, active, className, onClick, replace, style } = props;
  const navigate = useNavigate();
  const icon = useIcon(data?.icon);
  const endIcon = useIcon(data?.endicon);

  const cN = useMemo(() => {
    const variant = () => {
      switch (data?.variant) {
        case "navigation":
          return [
            "text-2xl sm:text-4xl lg:text-3xl",
            "px-2 md:px-4 pt-2 md-pt-3 pb-1 md:pb-1",
            "shadow-xl",
            "pointer-events-auto",
            active ? "bg-red-700 text-white" : "bg-gray-200 text-black",
            "hover:bg-red-700 hover:text-white",
            !active && "focus:bg-gray-200 focus:text-black",
            data?.variant,
          ].join(" ");
        case "primary":
          return [
            "text-2xl md:text-4xl",
            "px-3 pt-2 pb-1 md:px-4 md:pt-3 md:pb-2",
            "bg-red-700 text-white",
            "hover:text-red-700 hover:bg-white",
            data?.variant,
          ].join(" ");
        case "secondary":
        default:
          return [
            "text-2xl md:text-4xl",
            "px-3 pt-2 pb-1 md:px-4 md:pt-3 md:pb-2",
            "bg-white text-black",
            "hover:text-red-700 hover:bg-white",
            data?.variant,
          ].join(" ");
      }
    };

    const align = () => {
      if (data?.variant !== "navigation") {
        switch (data?.align) {
          case FlexAlign.end:
            return "self-end";
          case FlexAlign.center:
            return "self-center";
          case FlexAlign.start:
          default:
            return "self-start";
        }
      }
      return "";
    };
    return [
      data?.type === BlockType.BUTTON ? "button" : "",
      data?.type === BlockType.LINK ? "link" : "",
      // "px-4 pt-3 pb-2",
      "flex flex-row",
      "uppercase",
      "rounded md:rounded-xl",
      "w-fit",
      "border-0",
      data?.align,
      variant(),
      align(),
      className,
    ].join(" ");
  }, [data, className, active]);

  function handleClick(e: SyntheticEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (data?.to && data.variant !== "navigation") {
      const to2 = `/${data.to}${data?.anchor ?? ""}`;
      navigate(to2, { replace: replace });
    }

    if (onClick) {
      onClick(e);
    }
  }

  if (data?.to && data.variant !== "navigation") {
    const to2 = `/${data.to}${data?.anchor ?? ""}`;
    return (
      <Link onClick={handleClick} className={cN} to={to2} replace={replace}>
        {icon && <Icon icon={icon} />}
        {data?.text}
        {endIcon && <Icon icon={endIcon} isEnd />}
      </Link>
    );
  }

  if (data?.href && data.variant !== "navigation") {
    return (
      <a
        onClick={onClick}
        className={cN}
        href={data?.href}
        target={"_blank"}
        rel="noreferrer"
      >
        {icon && <Icon icon={icon} />}
        {data?.text}
        {endIcon && <Icon icon={endIcon} isEnd />}
      </a>
    );
  }

  return (
    <button onClick={handleClick} className={cN} style={style}>
      {icon && <Icon icon={icon} />}
      {data?.text}
      {endIcon && <Icon icon={endIcon} />}
    </button>
  );
}
