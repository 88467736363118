import { ResponsiveImage as KirbyResponsiveImage } from "@gk-lab/kirbyreact";
import React from "react";

interface Props {
  id?: string;
  className?: string;
  imageValues?: KirbyResponsiveImage;
}

export default function ResponsiveImage(props: Props): React.JSX.Element {
  const { id, className, imageValues } = props;

  return (
    <picture className={`w-full ${className ?? ""}`} id={id}>
      {imageValues?.xl?.url && (
        <source media="(min-width:1536px)" srcSet={imageValues?.xl?.url} />
      )}
      {imageValues?.lg?.url && (
        <source media="(min-width:1200px)" srcSet={imageValues?.lg?.url} />
      )}
      {imageValues?.md?.url && (
        <source media="(min-width:900px)" srcSet={imageValues?.md?.url} />
      )}
      {imageValues?.sm?.url && (
        <source media="(min-width:700px)" srcSet={imageValues?.sm?.url} />
      )}
      <img
        src={imageValues?.xs?.url}
        alt={imageValues?.xs?.name}
        className={"w-full h-auto"}
      />
    </picture>
  );
}
