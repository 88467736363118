import React from "react";
import { IKirbyImage } from "../../../.yalc/@gk-lab/kirbyreact";
import { Link } from "react-router-dom";

export default function GalleryThumb(props: IKirbyImage): React.JSX.Element {
  const { url, name } = props;
  return (
    <Link
      style={{ backgroundImage: `url(${url})` }}
      className={
        "bg-black aspect-square w-[50%] md:w-[33%] bg-top bg-center bg-cover cursor-pointer"
      }
      to={name}
    />
  );
}
