import React, { PropsWithChildren } from "react";
import { BlockPreview } from "../../types/Block";

interface Props {
  data?: BlockPreview;
}

export default function Preview(
  props: PropsWithChildren<Props>
): React.JSX.Element {
  const { children, data } = props;
  return (
    <div className={"preview flex flex-col w-full"}>
      {data?.title && (
        <h3 className={"text-2xl uppercase px-2 m-2 w-fit bg-black/80"}>
          {data.title}
        </h3>
      )}
      {children}
    </div>
  );
}
