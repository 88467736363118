import React, { useMemo } from "react";
import { BlockEvent } from "../../types/Block";

interface Props {
  data?: BlockEvent;
  scope?: string;
}

export default function Event(props: Props): React.JSX.Element {
  const { data, scope } = props;

  const wrapperClass = useMemo(() => {
    return [
      "flex flex-col",
      "w-full",
      "max-w-screen-md",
      "shadow-gray-700 shadow-sm",
      data?.past ? "text-gray-500" : "",
    ].join(" ");
  }, [data]);

  const isSmall = useMemo(() => {
    return scope === "preview" || data?.variant === "secondary";
  }, [data?.variant, scope]);

  return (
    <div className={"event flex w-full mb-4 justify-center px-2"}>
      <div className={wrapperClass}>
        <div className={"p-4 w-full bg-black/[.9] rounded-xl overflow-hidden"}>
          <h5
            className={["pb-0 mb-0", isSmall ? "text-2xl" : "text-4xl"].join(
              " "
            )}
          >
            {data?.date}
          </h5>
          {!isSmall && (
            <h5
              className={[
                "text-2xl pt-0 uppercase",
                isSmall ? "text-xl" : "text-2xl",
              ].join(" ")}
            >
              {data?.subtitle}
            </h5>
          )}
          <h3
            className={[
              "pb-0 mb-0 uppercase",
              isSmall ? "text-2xl" : "text-5xl",
            ].join(" ")}
          >
            {isSmall && `${data?.subtitle} | `}
            {data?.title}
          </h3>
          {data?.text && !isSmall && (
            <div
              className={
                "flex font-thin text-2xl text-gray-500 flex-grow w-full py-2"
              }
              dangerouslySetInnerHTML={{ __html: data.text }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
