import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BlockSection } from "../../types/Block";
import ResponsiveImage from "../ResponsiveImage/ResponsiveImage";
import { getBlockElement } from "../../utils/sectionUtils";
// import {JSONViewer} from "@gk-lab/kirbyreact";

type Props = {
  data?: BlockSection;
};

export default function Section(props: Props): React.JSX.Element {
  const { data } = props;
  const [wHeight, setWHeight] = useState(window.innerHeight);

  const onResize = useCallback(() => {
    setWHeight(window.innerHeight);
  }, []);

  // @ts-ignore
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const wrapperClass = useMemo(() => {
    let h = "";
    if (wHeight <= 700) {
      h = "min-h-[700px]";
    } else if (data?.fullheight) {
      h = "min-h-screen";
    }

    return ["relative flex", "w-full", "overflow-hidden", "text-3xl", h].join(
      " "
    );
  }, [data, wHeight]);

  const imageClass = useMemo(() => {
    return [
      "absolute z-0 h-full",
      data?.fullheight ? "min-h-screen" : "",
      //data?.title ? "top-[6rem] md:top-[8rem]" : "",
      "top-0",
    ].join(" ");
  }, [data]);

  const contentClass = useMemo(() => {
    return [
      "relative",
      "flex flex-col",
      "z-10 w-full",
      "gap-4",
      "justify-end items-center",
      data?.twc ?? "",
      "px-2 md:px-8",
    ].join(" ");
  }, [data]);

  const blocksClass = useMemo(() => {
    let a = "";
    switch (data?.align) {
      case "flex-end":
        a = "justify-end";
        break;
      case "flex-start":
        a = "justify-start";
        break;
      case "center":
      default:
        a = "justify-center";
        break;
    }

    return ["flex flex-col", a, "max-w-screen-md w-full h-full", "px-2"].join(
      " "
    );
  }, [data]);

  return (
    <section id={`section-${data?.id ?? ""}`} className={wrapperClass}>
      {/*<JSONViewer data={data} open />*/}
      {props.data?.image && (
        <ResponsiveImage
          imageValues={props.data?.image}
          className={imageClass}
        />
      )}
      {data?.title && (
        <div
          className={
            "absolute top-[2rem] w-full bg-white/[0.6] pt-4 pb-2 px-4 md:px-12"
          }
        >
          <h2
            className={
              "text-4xl md:text-7xl uppercase text-black max-w-screen-md"
            }
          >
            {data?.title}
          </h2>
        </div>
      )}
      <div className={contentClass}>
        <div className={blocksClass}>
          {data?.blocks &&
            data?.blocks.map((block, idx: number) => {
              return getBlockElement(block, idx, "section");
            })}
        </div>
      </div>
    </section>
  );
}
