import {
  Block,
  FlexAlign,
  IKirbyBlock,
  ResponsiveImage,
} from "@gk-lab/kirbyreact";
import { IKirbyImage } from "@gk-lab/kirbyreact/dist/esm/types/IKirbyImage";

export enum CustomBlockType {
  SECTION = "section",
  POST = "post",
  EVENT = "event",
  ANIMATION = "animation",
  PREVIEW = "preview",
  MEDIA = "media",
  GALLERY = "gallery",
}

export interface BlockSection extends IKirbyBlock {
  type: CustomBlockType.SECTION;
  id: string;
  title?: string;
  blocks?: (Block | CustomBlock)[];
  image?: ResponsiveImage;
  align?: FlexAlign;
  fullheight?: boolean;
  twc?: string;
}
export interface BlockAnimation extends IKirbyBlock {
  type: CustomBlockType.ANIMATION;
  id: string;
  blocks?: (Block | CustomBlock)[];
}

export interface BlockPost extends IKirbyBlock {
  type: CustomBlockType.POST;
  image?: ResponsiveImage;
  title: string;
  subtitle?: string;
  text?: string;
  to?: string;
  action?: (Block | CustomBlock)[];
  variant?: "primary" | "secondary";
}

export interface BlockEvent extends IKirbyBlock {
  type: CustomBlockType.EVENT;
  date: string;
  title: string;
  subtitle?: string;
  text?: string;
  href?: string;
  past?: boolean;
  variant?: "primary" | "secondary";
}

export interface BlockMedia extends IKirbyBlock {
  type: CustomBlockType.MEDIA;
  title: string;
  subtitle?: string;
  text?: string;
  yt?: string;
  to?: string;
  image?: ResponsiveImage;
}

export interface BlockGallery extends IKirbyBlock {
  type: CustomBlockType.GALLERY;
  title: string;
  url: string;
  images?: IKirbyImage[];
}

export interface BlockPreview extends IKirbyBlock {
  type: CustomBlockType.PREVIEW;
  title?: string;
  blocks?: (Block | CustomBlock)[];
  variant?: "primary" | "secondary";
  kind?: string;
  total?: number;
}

export type CustomBlock =
  | BlockAnimation
  | BlockSection
  | BlockPost
  | BlockEvent
  | BlockPreview
  | BlockGallery
  | BlockMedia;
