import React from "react";
import { IKirbyImage } from "../../../.yalc/@gk-lab/kirbyreact";
import GalleryThumb from "../GalleryThumb/GalleryThumb";
import { BlockGallery } from "../../types/Block";

interface Props {
  data?: BlockGallery;
}

export default function Gallery(props: Props): React.JSX.Element {
  const { data } = props;

  return (
    <div className={"flex flex-row flex-wrap w-full"}>
      {data?.images?.map((image: IKirbyImage) => {
        return (
          <GalleryThumb
            key={image.url}
            name={image.name}
            url={image.thumb ?? image.url}
          />
        );
      })}
    </div>
  );
}
