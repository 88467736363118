import React from "react";
import { BlockMedia, CustomBlockType } from "../../types/Block";
import { BlockType } from "@gk-lab/kirbyreact";
import Video from "../Video/Video";
import Post from "../Post/Post";

interface Props {
  data?: BlockMedia;
}

export default function Media(props: Props): React.JSX.Element {
  const { data } = props;

  if (Boolean(data?.yt)) {
    return <Video data={data} />;
  } else if (data) {
    return (
      <Post
        data={{
          type: CustomBlockType.POST,
          title: data.title,
          subtitle: data.subtitle,
          text: data.text,
          image: data.image,
          to: data.to,
          action: [
            {
              type: BlockType.BUTTON,
              variant: "secondary",
              text: "Mehr",
              to: data.to,
            },
          ],
        }}
      />
    );
  }

  return <div />;
}
